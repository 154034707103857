/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.eh').matchHeight({ byRow: false });
        $('.eh-byrow').matchHeight({ byRow: true });
        $('a.dropdown-item').matchHeight({ byRow: true });

        // Fixed scrollbar
        $(window).scroll(function() {
          if ( ( $(document).scrollTop() > 72 ) && $('.mdcheck').is(":visible")) {
            $('body').addClass('sticky');
            $('.site-header').addClass('sticky');
          }
          else {
            $('body').removeClass('sticky');
            $('.site-header').removeClass('sticky');
          }
        });

        /**********
         * MOBILE DEVICE DETECTION
         **********/

        var isMobile = false; //initiate as false
        //device detection
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

        /**********
         * DROPDOWN MENU CHANGES
         * Dropdown menus to work on hover as well as click
         * but only on desktop
         **********/

        // If not on a touch-enabled device, and screen size is > 768px (e.g. desktop)
        // we want buttons to work on click, and dropdowns to appear on mouseover
        if ( (isMobile === false) && $('#mobile-indicator').is(':visible') ) {
            
            /*$('#menu-primary-menu .dropdown').on('mouseenter mouseleave', function() {
              $(this).toggleClass("show");
              $(this).find(".dropdown-menu").toggleClass("show");
            });*/

            $('#menu-primary-menu .dropdown').on('mouseenter', function() {
              $(this).addClass("show");
              $(this).find(".dropdown-menu").addClass("show");
            });

            $('#menu-primary-menu .dropdown').on('mouseleave', function() {
              $(this).removeClass("show");
              $(this).find(".dropdown-menu").removeClass("show");
            });

            //$('#menu-primary-menu .dropdown a').removeClass("dropdown-toggle");
            $('#menu-primary-menu .dropdown a').attr("data-toggle",'');
            //$('#menu-primary-menu .dropdown').removeClass("dropdown");
            
        }
        // If on a touch enabled device, and screen size is > 768px (e.g. tablet)
        // we want buttons to work on click, with no dropdowns
        else if ( $('#mobile-indicator').is(':visible') ) {
            //$('#menu-primary-menu .dropdown a').removeClass("dropdown-toggle");
            $('#menu-primary-menu .dropdown a').attr("data-toggle",'');
        }
        // If on a touch enabled device with a small screen (e.g. mobile),
        // we want everything to act as normal
        else {

        }


        /**********
         * HOVER BOXES SHOW ON TOUCH
         * Make the description show up when you touch the hover boxes
         **********/
        /*$('.block-flip .link-item').on("click",function(){
            console.log('clic');
            $('.block-flip .link-item').find('.back.active').css('display','none');
            $(this).find('.back').css('display','table');
            $(this).find('.back').addClass('active');
            event.stopPropagation();
        });*/

        $(window).on("click touchstart",function() { 
        // SOMEHOW, THE ABOVE LINE ALONE ALLOWS AN IPAD TO RESPOND TO A TOUCH IN THE SAME WAY AS A DESKTOP RESPONDS TO A CLICK
            //console.log('second clic amended');
            /*$('.block-flip .link-item .back').removeAttr( 'style' );
            $('.block-flip .link-item .back').removeClass('active');*/
        });


        /* SMOOTH SCROLLING */
        /* https://css-tricks.com/snippets/jquery/smooth-scrolling/ */

        // Select all links with hashes
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
              && 
              location.hostname == this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top - 80
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  };
                });
              }
            }
          });

          /**********
          * SCROLL TO TOP BUTTON
          **********/

          // change the value with how many pixels scrolled down the button will appear
          var amountScrolled = 200;

          $(window).scroll(function() {
              if ( $(window).scrollTop() > amountScrolled ) {
                  $('.back-to-top-inner a').fadeIn('slow');
              } else {
                  $('.back-to-top-inner a').fadeOut('slow');
              }
          });

          $('a.back-to-top').click(function() {
              $('html, body').animate({
                  scrollTop: 0
              }, 700);
              return false;
          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
